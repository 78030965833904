import { vue } from '@/main'

export default function () {
  return [

    {
      key: 'orgIds',
      type: 'org',
      props: {
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'buTypes',
      label: 'departments',
      type: 'department',
      props: {
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'buIds',
      label: 'businessUnit',
      type: 'bu',
      props: {
        clearable: true,
        multiple: true
      }
    },
 /*    { key: 'banner' }, { key: 'affiliate' }, { key: 'campaign' }, */

  ]
}
