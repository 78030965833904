<template>
  <div>

    <filter-card :filter-key="viewKey" dashboard v-model="filter" v-bind:fields="filterFields"
                 v-bind:hidden-fields="{'buTypes': viewKey!=='sales-dashboard' }"
    />

    <calendar-bar v-model="dateRange" @input="refresh"/>
    <b-overlay :show="loading" rounded="sm" opacity="0.6">

      <b-row class="realEstate-statistics">
        <b-col lg="3" sm="6">
          <statistic-card-horizontal icon="UserIcon" :statistic="statistics.ftdAndClients" statistic-title="FTD / Total Clients"
                                     :statistic-footer="'Online Clients: '+statistics.onlineClients"
          />
        </b-col>
        <b-col lg="3" sm="6">
          <statistic-card-horizontal icon="dollar-sign" color="success" :statistic="convertToEURO(statistics.totalDeposits)"
                                     statistic-title="Total Deposits"
                                     :statistic-footer="'Net Deposits: '+ convertToEURO(statistics.netDeposits)"
          />
        </b-col>
        <b-col lg="3" sm="6">
          <statistic-card-horizontal icon="MessageSquareIcon" color="warning"
                                     :statistic="convertToEURO(closedPL)" statistic-title="Closed P&L"
                                     :statistic-footer="'Open P&L: '+ convertToEURO(openPL)"
          />
        </b-col>
        <b-col lg="3" sm="6">
          <statistic-card-horizontal icon="UserIcon" color="danger" :statistic="convertToEURO(statistics.totalWithdrawals)"
                                     statistic-title="Withdrawals"
                                     :statistic-footer="' Withdrawals rate: '+(statistics.totalWithdrawals/statistics.totalDeposits*(-100)).toFixed(2)+'%'"
          />
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <!--User deposit/count-->
        <b-col lg="6" sm="12">
          <b-card>
            <b-card-header>
              <div class="d-flex align-items-center justify-content-end">
                <h5 class="mr-1 mb-0">{{ $t('Deposit amount (€)') }}</h5>
                <b-form-checkbox switch v-model="showUserDeposit"/>
                <h5 class="ml-50 mb-0">{{ $t('Deposit count') }}</h5>
              </div>
            </b-card-header>
            <b-card-body class="text-center">
              <user-sales-bar-chart v-bind:chart-data="userDepositData" v-bind:currency-format="showUserDeposit" v-bind:loading="loading"/>
            </b-card-body>
          </b-card>
        </b-col>
        <!--Country deposit/count-->
        <b-col lg="6" sm="12">
          <b-card>
            <b-card-header>
              <div class="d-flex align-items-center justify-content-end">
                <h5 class="mr-1 mb-0">{{ $t('Deposit amount (€)') }}</h5>
                <b-form-checkbox switch v-model="showCountryDeposit"/>
                <h5 class="ml-50 mb-0">{{ $t('Deposit count') }}</h5>
              </div>
            </b-card-header>
            <b-card-body class="text-center">
              <country-deposits-bar-chart v-bind:chart-data="countryDepositData"  v-bind:currency-format="showCountryDeposit" v-bind:loading="loading"/>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <!--User deposit/count-->
        <b-col lg="6" sm="12">
          <b-card >
            <b-card-body class="text-center">
              <client-status-pie-chart v-bind:chart-data="statistics.statusMapCount" v-bind:loading="loading"/>
            </b-card-body>
          </b-card>
        </b-col>
        <!--Country deposit/count-->
        <b-col lg="6" sm="12">
          <b-card>
            <b-card-body class="text-center">
              <client-country-pie-chart v-bind:chart-data="statistics.clientCountryMapCount" v-bind:loading="loading"/>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import CalendarBar from '@/components/widget/CalendarBar';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal';
import mixinBase from '@/components/mixin/mixinBase';

import { mapActions, mapGetters } from 'vuex';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import DashboardSearchRequest from '@/model/Dashboard/DashboardSearchRequest';
import FilterCard from '@/components/widget/FilterCard';
import SalesDashboardFilterFields from '@/model/filter/SalesDashboardFilterFields';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import UserSalesBarChart from '@/components/chart/UserSalesBarChart.vue'
import CountryDepositsBarChart from '@/components/chart/CountryDepositsBarChart.vue'
import ClientStatusPieChart from '@/components/chart/ClientStatusPieChart.vue'
import ClientCountryPieChart from '@/components/chart/ClientCountryPieChart.vue'
import BreadcrumbButton from '@/components/widget/breadcrumbButton.vue';

export default {
  name: 'SalesDashboard',
  mixins: [mixinBase, mixinNotifications, mixinPermissions],

  components: {
    BreadcrumbButton,
    ClientCountryPieChart,
    ClientStatusPieChart,
    CountryDepositsBarChart,
    UserSalesBarChart,
    CalendarBar,
    FilterCard,
    StatisticCardHorizontal
  },

  data: () => ({
    filter: DashboardSearchRequest(),
    filterFields: [],
    dateRange: { from: '', until: '' },
    loading: false,
    statistics:{
      totalDeposits: 0,
      netDeposits: 0,
      totalWithdrawals: 0,
      onlineClients: 0,
      ftdAndClients: '0/0',
      userDeposit: {},
      userCount: {},
      countryDeposit: {},
      countryCount: {},
      statusMapCount: {},
      clientCountryMapCount: {},
    },
    closedPL: 0,
    openPL: 0,
    showUserDeposit: false, // Initially showing amount in euros
    showCountryDeposit: false, // Initially showing amount in euros
  }),
  watch: {
    /* filter: {
      handler: 'refresh',
      deep: true
    } */
  },
  mounted() {
    this.$root.$on('dashboard::refresh', this.refresh);
  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    this.$root.$on('dashboard::refresh', this.refresh);
  },
  computed: {
    ...mapGetters('appConfig', ['isDarkSkin']),
    viewKey() {
      return this.$route.name || 'sales-dashboard';
    },
    userDepositData(){
      return !this.showUserDeposit ? this.statistics.userDeposit : this.statistics.userCount;
    },
    countryDepositData(){
      return !this.showCountryDeposit ? this.statistics.countryDeposit : this.statistics.countryCount;
    }
  },
  created() {
    this.filterFields = SalesDashboardFilterFields();

  },
  methods: {
    ...mapActions('dashboard', ['getSalesDashboard']),
    refresh() {
      let $this = this;
      this.loading = true;
      this.filter.range = { from: $this.dateRange.from, until: $this.dateRange.until };
      this.getSalesDashboard(this.filter)
          .then(
              (data) => {
                $this.setData(data);
              },
              err=>$this.showServerErrorNotification('getSalesDashboard', 'dashboard', err)
          ).finally(() => {
        this.loading = false;
      });
    },
    setData(data) {
      this.statistics.totalDeposits = data.totalDeposits;
      this.statistics.netDeposits = data.netDeposits;
      this.statistics.totalWithdrawals = data.totalWithdrawals;
      this.statistics.onlineClients = data.clientsOnline;
      this.statistics.ftdAndClients = data.ftdsCount + '/' + data.clientsCount;
      this.statistics.userDeposit = data.userDeposit;
      this.statistics.userCount = data.userCount;
      this.statistics.countryDeposit = data.countryDeposit;
      this.statistics.countryCount = data.countryCount;
      this.statistics.statusMapCount = data.statusMapCount;
      this.statistics.clientCountryMapCount = data.clientCountryMapCount;
    },
/*    generateRandomColors(length) {
      const colors = [];
      let colorCount = 0;
      while (colorCount < length) {
        const randomColor = '#' + Math.floor(Math.random() * 16777215)
            .toString(16);
        colors.push(randomColor);
        colorCount++;
      }
      return colors;
    },*/
  }
};
</script>

<style scoped>
.dark-layout .apexcharts-title-text {
  background-color: rgba(255, 255, 255, 0);
  color: white !important;
}
</style>
